import React, { useEffect } from "react";
import AllAcountsSection from "../../Components/AllAccountsSection/AllAcountsSection";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import SavePlusAccountComponent from "../../Components/SavePlusAccount/SavePlusAccountComponent";
// import Arrow_down from "../../assets/images/IZB/arrow_down.png";
import { AiOutlineArrowRight } from "react-icons/ai";
import AutoFinanceAccordion from "./AutoFinanceAccordion";
import { question } from "./AutoFinanceData";
console.log(question);

const IndoVehicleFinance = () => {
    const Arrow_down = "https://izbassetsmain.blob.core.windows.net/izbwebassets/arrow_down.png";
    const img1 = "https://izbassetsmain.blob.core.windows.net/izbwebassets/IZB-21.png";
    const BackgroundBrundcrumb = "https://izbassetsmain.blob.core.windows.net/izbwebassets/autofinance-banner.png";
    
      useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
      const accountContent =
        "Purchase of brand new and second hand utility vehicle.Age of Second- hand vehicle not to exceed 5 years.";
      return (
        <>
          <Breadcrumb Heading="Indo Vehicle Finance" img={BackgroundBrundcrumb} show={true} btnTxt="Apply Now" />
          <SavePlusAccountComponent
            heading="Indo Vehicle Finance"
            subHeading="Know About"
            content={accountContent}
            btnTxt="Apply Now"
            img={img1}
            logo={true}
          />
    
          <section
            className="page-contaProcessingInsurance -area"
            style={{ backgroundColor: "#FFF5E7" }}
          >
            <div className="container">
              <div className="row">
                <div className="col-xl-12">
                  <div className="page-contains-box">
                    <div className="page-contains-btn">
                      <ul className="navigation clearfix scroll-nav">
                        <li className="current">
                          <a href="#benefits">
                            <img src={Arrow_down} className="me-1" />
                            Features
                          </a>
                        </li>
                      
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
    
          <section id="MSMEAccodion">
            <div className="container justify-content-center mt-5">
              <div className="faq-style1__content">
                <ul className="accordion-box mb-5">
                  {question.map((item) => {
                    const { id } = item;
                    return <AutoFinanceAccordion key={id} {...item} />;
                  })}
                </ul>
              </div>
            </div>
          </section>
    
          <div className="container">
              <div className="bg-light my-3"   style={{
                      padding: "20px 0px 20px 60px",
                    }}>
              <h6 style={{ color: "#9E1B1E" }}>Terms and conditions apply</h6>
            </div>
          </div>

          <div className="container">
              <div className="bg-light my-3"   style={{
                      padding: "20px 0px 20px 60px",
                    }}>
              <h6 style={{ color: "#9E1B1E" }}>Please contact your nearest branch for further details</h6>
            </div>
          </div>
          <AllAcountsSection />
        </>
      );
    };
    
export default IndoVehicleFinance

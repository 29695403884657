import React from "react";
import Breadcrumb from "../../Components/Breadcrumb/Breadcrumb";
import AllAcountsSection from "../../Components/AllAccountsSection/AllAcountsSection";
import SavePlusAccountComponent from "../../Components/SavePlusAccount/SavePlusAccountComponent";
import ContainsArea from "../../Components/ContainsArea/ContainsArea";


import {
  AiOutlineArrowRight,
  AiOutlineArrowUp,
  AiFillCaretRight,
} from "react-icons/ai";
import { accountContains } from "../../Data/ContainAreaData";
import { useState } from "react";
import { useEffect } from "react";
console.log(accountContains);

const IndoHomeLoan = () => {
  const BackgroundBrundcrumb = "https://izbassetsmain.blob.core.windows.net/izbwebassets/indo-home-loan-banner.png";
const img1 = "https://izbassetsmain.blob.core.windows.net/izbwebassets/IZB-11.png";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const accountContent =
    "Looking to buy, build or renovate. Spruce up your property portfolio with an IZB home loan. Whether you would like a new kitchen or want to give your lounge a new lease of life, you could get more from your home.";
  const data = [
    "Eligibility",
    "Purpose",
    "security",
    "customers contribution",
    "Repayment Period",
  ];
  const [show, setShow] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Breadcrumb Heading="Indo Home Loan" img={BackgroundBrundcrumb} show={true} btnTxt="Apply Now"/>
      <SavePlusAccountComponent
        heading="Indo Home Loan"
        subHeading="Know About"
        btnTxt="Apply Now"
        content={accountContent}
        img={img1}
        logo={true}
      />
      <ContainsArea title={accountContains} />
      <section className="faq-section">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 justify-content-center">
              <div className="faq-style1__content">
                <ul className="accordion-box mt-5">
                <li className="accordion block active-block">
                    <div
                      className="acc-btn active"
                      style={{
                        backgroundColor: "#9E1B1E",
                        backgroundSize: "20px",
                      }}
                    >
                      <div className="icon-outer text-white">
                        {show ? <AiOutlineArrowRight /> : <AiOutlineArrowUp />}
                      </div>
                      <h3 className="text-white" onClick={() => setShow(!show)}>
                      Features
                      </h3>
                    </div>
                    <div className="acc-content current">
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          Low interest rate
                        </p>
                      )}
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          Tailored repayment periods up to 300 months 
                        </p>
                      )}
                    </div>
                  </li>


                  <li className="accordion block active-block">
                    <div
                      className="acc-btn active"
                      style={{
                        backgroundColor: "#9E1B1E",
                        backgroundSize: "20px",
                      }}
                    >
                      <div className="icon-outer text-white">
                        {show ? <AiOutlineArrowRight /> : <AiOutlineArrowUp />}
                      </div>
                      <h3 className="text-white" onClick={() => setShow(!show)}>
                        Eligibility
                      </h3>
                    </div>
                    <div className="acc-content current">
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          Salaried employees on either permanent or contractual terms with reputable corporate institution such as government departments, parastatals and the private sector
                        </p>
                      )}
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          Self employed business people with legal identifiable and regular source of income
                        </p>
                      )}
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          Parents, spouses and children may be accepted as co borrowers to improve the entitlement/repayment capacity of the borrower. However, the number of co-borrowers should not exceed two.
                        </p>
                      )}
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          Indo Home loan is available to existing customers of the bank who have maintained banking relationships for at six months.
                        </p>
                      )}
                    </div>
                  </li>

                  <li className="accordion block active-block">
                    <div
                      className="acc-btn active"
                      style={{
                        backgroundColor: "#9E1B1E",
                        backgroundSize: "20px",
                      }}
                    >
                      <div className="icon-outer text-white">
                        {show ? <AiOutlineArrowRight /> : <AiOutlineArrowUp />}
                      </div>
                      <h3 className="text-white" onClick={() => setShow(!show)}>
                      Currency
                      </h3>
                    </div>
                    <div className="acc-content current">
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          ZMW 
                        </p>
                      )}
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          USD (subject to a maximum tenure) of 84 months and subject to the borrower demonstrating their ability to generate foreign currency inflows over the proposed tenure.
                        </p>
                      )}
                    </div>
                  </li>

                  <li className="accordion block active-block">
                    <div
                      className="acc-btn active"
                      style={{
                        backgroundColor: "#9E1B1E",
                        backgroundSize: "20px",
                      }}
                    >
                      <div className="icon-outer text-white">
                        {show ? <AiOutlineArrowRight /> : <AiOutlineArrowUp />}
                      </div>
                      <h3 className="text-white" onClick={() => setShow(!show)}>
                      Interest Rate
                      </h3>
                    </div>
                    <div className="acc-content current">
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          BOZ Policy Rate plus margin
                        </p>
                      )}
                    
                    </div>
                  </li>







                  {/* <li className="accordion block active-block">
                    <div
                      className="acc-btn active"
                      style={{
                        backgroundColor: "#9E1B1E",
                        backgroundSize: "20px",
                      }}
                    >
                      <div className="icon-outer text-white">
                        {show ? <AiOutlineArrowRight /> : <AiOutlineArrowUp />}
                      </div>
                      <h3 className="text-white" onClick={() => setShow(!show)}>
                        Purpose
                      </h3>
                    </div>
                    <div className="acc-content current">
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          Outright Purchase of a ready-made
                        </p>
                      )}
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          Purchase of a Plot and construction of a house or
                          construction of a house on an already existing plot,
                          within a maximum period of 18 months
                        </p>
                      )}
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          Home Improvement – For
                          repairs/renovation/alteration/extension in the
                          existing house/ﬂat
                        </p>
                      )}
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          Takeover/Reﬁnance of home loans availed from other
                          ﬁnancial institutions
                        </p>
                      )}
                    </div>
                  </li> */}

<li className="accordion block active-block">
                    <div
                      className="acc-btn active"
                      style={{
                        backgroundColor: "#9E1B1E",
                        backgroundSize: "20px",
                      }}
                    >
                      <div className="icon-outer text-white">
                        {show ? <AiOutlineArrowRight /> : <AiOutlineArrowUp />}
                      </div>
                      <h3 className="text-white" onClick={() => setShow(!show)}>
                      Purpose of the loan
                      </h3>
                    </div>
                    <div className="acc-content current">
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          Outright purchase of a readymade house
                        </p>
                      )}
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          Purchase of a plot and construction of a house or construction of a house on an already existing plot within a maximum period of 18 months.
                        </p>
                      )}
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          Home improvements – for repairs, renovation/alteration/extension in the existing house/flat
                        </p>
                      )}
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          Takeover/refinancing of home loan availed from other financial intuitions subject to terms and conditions
                        </p>
                      )}
                    </div>
                  </li>










                  <li className="accordion block active-block">
                    <div
                      className="acc-btn active"
                      style={{
                        backgroundColor: "#9E1B1E",
                        backgroundSize: "20px",
                      }}
                    >
                      <div className="icon-outer text-white">
                        {show ? <AiOutlineArrowRight /> : <AiOutlineArrowUp />}
                      </div>
                      <h3 className="text-white" onClick={() => setShow(!show)}>
                        Security
                      </h3>
                    </div>
                    <div className="acc-content current">
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          Legal mortgage of property purchased or developed out
                          of bank ﬁnance
                        </p>
                      )}
                    </div>
                  </li>


                  <li className="accordion block active-block">
                    <div
                      className="acc-btn active"
                      style={{
                        backgroundColor: "#9E1B1E",
                        backgroundSize: "20px",
                      }}
                    >
                      <div className="icon-outer text-white">
                        {show ? <AiOutlineArrowRight /> : <AiOutlineArrowUp />}
                      </div>
                      <h3 className="text-white" onClick={() => setShow(!show)}>
                      Repayment period
                      </h3>
                    </div>
                    <div className="acc-content current">
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          For salaried borrowers – 300 months within the borrower attaining the age of 60 years.
                        </p>
                      )}
                      {show && (
                        <p className="px-5">
                          <AiFillCaretRight className="me-1" />
                          For non-salaried borrowers – 300 months within the borrower attaining 60 years. In the case of repairs/renovation/alteration of existing house/flat repayment should be made within maximum period of 120 months. 
                        </p>
                      )}
                    </div>
                  </li>


                  <li className="accordion block active-block">
                    <div
                      className="acc-btn active"
                      style={{
                        backgroundColor: "#9E1B1E",
                        backgroundSize: "20px",
                      }}
                    >
                      <div className="icon-outer text-white">
                        {show ? <AiOutlineArrowRight /> : <AiOutlineArrowUp />}
                      </div>
                      <h3 className="text-white" onClick={() => setShow(!show)}>
                      How to Apply
                      </h3>
                    </div>
                    <div className="acc-content current">
                      {show && <h5 className="px-5">To apply now, email <a href="mailto:credit@izb.co.zm">credit@izb.co.zm</a> or call 8002
                        </h5>}
              
                    </div>
                  </li>

                </ul>
              </div>
              <div
                className="my-3"
                style={{
                  backgroundColor: "#9E1B1E",
                  padding: "20px 0px 20px 60px",
                }}
              >
                <h6 className="text-white">Terms and Conditions Apply</h6>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AllAcountsSection />
    </>
  );
};

export default IndoHomeLoan;

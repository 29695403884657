import React from "react";
import sliderImg from "../../assets/images/IZB/IZB Website Skin/Home_Page/banner.png";
import { FiCheckCircle } from "react-icons/fi";
import BankingMenu from "./BankingMenu";
import "./Hero.css";
import { Link } from "react-router-dom";

const HeroSlide6 = () => {
const fastserve = "https://izbassetsmain.blob.core.windows.net/izbwebassets/indo-fast-serve_png.png";

  const HeroBgimg = {
    // backgroundImage: `url(${sliderImg})`,
    height: "75vh",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    overflow: "hidden",
  };
  return (
    <Link to="/IndoHomeLoan">
    <div style={HeroBgimg} className="HeroSlider6">
      <div className="auto-container sliderContent h-100">
        <div className="h-75 position-relative ">
          <div className="d-flex justify-content-end align-items-center h-100">
            <div className="pt-4">
       
            </div>
            {/* <span className="border h-50 mx-3"></span> */}
            <div className="flex-column flex-wrap align-self-xl-center">
             
      
            </div>
          </div>
        </div>
        {/* <BankingMenu /> */}
      </div>
    </div>
    </Link>
  );
};

export default HeroSlide6;
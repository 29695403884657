export const question = [
  {
    id: 1,
    question: "Features",
    answer: [
      {
        item: "Low interest rate",
      },
      {
        item: "Applications processed within 72hrs (Terms and conditions apply)",
      },
      {
        item: "Tailored repayment periods up to 72 months",
      },
      {
        item: "Reimbursement on cost of brand-new vehicles",
      },
    ],
  },



  {
    id: 2,
    question: "Eligibility",
    answer: [
      {
        item: "Government Departments/Ministries/Parastatals",
      },
      {
        item: "Private Limited Companies",
      },
      {
        item: "Partnership Firms",
      },
      {
        item: "Proprietorships",
      },
      {
        item: "Non-Governmental Organisations ",
      },
      {
        item: "Existing customers of the bank who hold current accounts and have maintained a satisfactory banking relationship with the bank for at least one year",
      },
    ],
  },

  {
    id: 3,
    question: "Currency",
    answer: [
      {
        item: "ZMW",
      },
      {
        item: "USD",
      },
    ],
  },

  {
    id: 4,
    question: "Interest Rate",
    answer: [
      {
        item: "BOZ Policy Rate plus margin",
      },
    ],
  },

  {
    id: 5,
    question: "Purpose of the loan",
    answer: [
      {
        item: "Finance the purchase of brand new two-wheeler and four-wheeler vehicles, including electric vehicles and super bikes from an authorized automobile dealer for personal use",
      },
      {
        item: "To finance the purchase of second had four-wheeler vehicles (not exceeding 5 years old) from reputable dealers for personal use.",
      },
      {
        item: "To finance the purchase of water vehicles such as motorboats, sports boats and other watercraft for personal use",
      },
      {
        item: "Takeover of vehicles from other financial institutions",
      },
      {
        item: "Reimbursement of cost of four-wheeler vehicles purchased from own source (Terms and conditions apply)",
      },
    ],
  },


  {
    id: 6,
    question: "Repayment Period",
    answer: [
      {
        item: "For brand new vehicles: maximum 72 months ",
      },
      {
        item: "For pre-owned vehicles: maximum 60 months",
      },
    ],
  },


  {
    id: 7,
    question: "Quantum of Loan",
    answer: [
      {
        item: "80% of the cost of the vehicle, subject to repaying capacity+",
      },
    ],
  },

  {
    id: 8,
    question: "Processing Fee",
    answer: [
      {
        item: "2.5% ot 3%",
      },
    ],
  },
  {
    id: 9,
    question: "Processing Insurance Fee",
    answer: [
      {
        item: "Charge over motor vehicle financed",
      },
      {
        item: "Employer’s undertaking to remit loan instalments to our bank during tenor of the loan and terminal benefits/gratuity in case of loss of separation",
      },
    ],
  },
  {
    id: 10,
    question: "Requirements",
    answer: [
      {
        item: "Customer to complete our standard Auto Fin loan application form attaching the following:",
      },
      {
        item: "Copy of invoice for brand new vehicles from a reputable /registered dealer",
      },
      {
        item: "Letter of sale in case of second hand vehicles with copy of white book and NRC of the seller",
      },
      {
        item: "Engineer’s report from a reputable garage in case of secondhand vehicles certify",
      },
      {
        item: "If importing, copy of invoice should be made available and estimated cost of duty and transportation to be advised",
      },
      {
        item: "Loan amount and customer’s contribution shall be remitted to the supplier of the vehicle as a lump sum",
      },
      {
        item: "Copy of pay slip or proof of income in case of self employed",
      },
      {
        item: "Copy of NRC of the vendor",
      },
    ],
  },
  {
    id: 11,
    question: "How to Apply",
    answer: [
      {
        item: "To apply now, email vaf@izb.co.zm or call 0211 226294",
      },
      
    ],
  },
];
